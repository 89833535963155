define("moments/adapters/application", ["exports", "active-model-adapter", "moments/config/environment"], function (_exports, _activeModelAdapter, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.$.ajaxSetup({
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    }
  });

  var _default = _activeModelAdapter.default.extend({
    host: _environment.default.APP.apiHost,
    namespace: _environment.default.APP.apiNamespace
  });

  _exports.default = _default;
});