define("moments/services/state-manager", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      inject = _Ember.inject;
  var _EmberRSVP = Ember.RSVP,
      Promise = _EmberRSVP.Promise;

  var _default = Ember.Service.extend({
    ajax: inject.service(),
    cookieHandler: inject.service(),
    dataBranchHandler: inject.service(),
    googleAnalytics: inject.service(),
    googleTagManager: inject.service(),
    outcomeHandler: inject.service(),
    redirectHandler: inject.service(),
    sessionCreator: inject.service(),
    store: inject.service(),
    torii: inject.service(),
    currentDepth: 1,
    redirectCookieKey: 'oauth2_redirect_url',
    savePromises: null,
    session: null,
    socialProviders: {
      'facebook-login-button': 'facebook-oauth2',
      'google-login-button': 'google-oauth2'
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set('savePromises', []);
    },
    waitForSaves: function waitForSaves() {
      return Ember.RSVP.all(this.get('savePromises'));
    },
    createSession: function createSession(campaign) {
      var _this = this;

      return this.get('sessionCreator').create(campaign).then(function (session) {
        _this.set('session', session);
      });
    },
    getInitialState: function getInitialState() {
      var node = this.get('session.node');
      return this.getNextActionableNode(node);
    },
    getNextState: function getNextState(contentPiece) {
      var formFields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var edge = contentPiece.get('edge');
      var nodeId = contentPiece.get('contentCollection.id');
      var node = this.lookupRecord('node', nodeId);
      var responsePromise = this.saveResponse(node, edge, formFields);
      this.setSessionIsViewed();
      this.sendThirdPartyResponse(contentPiece, formFields);
      return this.getNextActionableNode(node, edge, responsePromise);
    },
    hasRespondedTo: function hasRespondedTo(contentPiece) {
      var nodeId = contentPiece.get('contentCollection.id');
      var node = this.lookupRecord('node', nodeId);
      return node && node.get('response.endedAt');
    },
    saveSocialShare: function saveSocialShare(socialMediaType, contentCollection) {
      this.setSessionIsViewed();
      var sharePromise = this.get('store').createRecord('social-share', {
        contentCollection: contentCollection,
        socialMediaType: socialMediaType,
        session: this.get('session')
      }).save();
      this.pushPromise(sharePromise);
    },
    triggerSocialLogin: function triggerSocialLogin(formField) {
      var _this2 = this;

      var socialType = formField.get('type');
      var provider = this.get('socialProviders')[socialType];

      var _this$getProperties = this.getProperties(['cookieHandler', 'redirectCookieKey']),
          cookieHandler = _this$getProperties.cookieHandler,
          redirectCookieKey = _this$getProperties.redirectCookieKey;

      this.setSessionIsViewed();

      if (!provider) {
        return;
      }

      cookieHandler.writeCookie(redirectCookieKey, window.location.href);
      return new Promise(function (resolve, reject) {
        _this2.get('torii').open(provider).then(function (auth) {
          var contentPiece = formField.get('contentPiece');

          var currentNode = _this2.lookupRecord('node', contentPiece.get('contentCollection.id'));

          var edge = contentPiece.get('edge');

          _this2.sendLoginRequest(provider, auth, formField);

          _this2.saveResponse(currentNode, edge);

          var nextNode = _this2.getNextActionableNode(currentNode, edge);

          resolve(nextNode);
        }, reject).finally(function () {
          cookieHandler.clearCookie(redirectCookieKey);
        });
      });
    },
    handlePreviewScreen: function handlePreviewScreen(contentCollection, outcomeId) {
      var node = this.lookupRecord('node', contentCollection.get('id'));
      this.createResponse(node);

      if (outcomeId) {
        this.setPreviewOutcome(contentCollection, outcomeId);
      } else {
        this.handleOutcome(node);
      }
    },
    setPreviewOutcome: function setPreviewOutcome(contentCollection, outcomeId) {
      var outcome = this.lookupRecord('outcome', outcomeId);
      var session = this.get('session');
      contentCollection.set('outcome', outcome);
      session.set('outcome', outcome);
    },
    setSessionIsViewed: function setSessionIsViewed() {
      var session = this.get('session');

      if (!session.get('isViewed')) {
        session.set('isViewed', true);
        session.save();
      }
    },

    /* Private */
    getNextActionableNode: function getNextActionableNode(node, edge, previousUserResponse) {
      var childNode;

      if (edge) {
        childNode = edge.get('childNode');
      } else {
        childNode = node;
      }

      childNode = this.handleNonContentNodes(childNode, previousUserResponse);
      this.handleOutcome(childNode);

      if (childNode.get('isContentNode')) {
        this.createResponse(childNode);
        this.sendThirdPartyView(childNode);
        return this.lookupRecord('content-collection', childNode.get('id'));
      } else if (childNode.get('resourceType') === 'Redirect') {
        var session = this.get('session');
        var redirect = this.lookupRecord('redirect', childNode.get('id'));
        var redirectHandler = this.get('redirectHandler');
        redirectHandler.setFinalUrl(redirect, session);
        return redirect;
      }
    },
    handleNonContentNodes: function handleNonContentNodes(node, previousUserResponse) {
      var nonContentNodes = ['DataBranch', 'Email', 'TriggerText', 'EmailOutcome', 'TriggerTextOutcome'];
      var traversedEdge;

      while (node && nonContentNodes.indexOf(node.get('resourceType')) > -1) {
        var resourceType = node.get('resourceType');

        if (resourceType === 'DataBranch') {
          traversedEdge = this.handleDataBranch(node);
        } else {
          traversedEdge = this.handleEmailOrText(node, previousUserResponse, resourceType);
        }

        node = traversedEdge ? traversedEdge.get('childNode') : null;
      }

      return node;
    },
    handleEmailOrText: function handleEmailOrText(node, previousUserResponse, resourceType) {
      var _this3 = this;

      var session = this.get('session');
      var edge = node.get('edges.firstObject'); // In the case of an email node being first (unlikely), create a resolved promise

      previousUserResponse = previousUserResponse || Promise.resolve(); // Delays the sending of emails until the previous response has been saved (assuming form submission happens on the previous screen)
      // If a form field submission was made earlier in the campaign, we can assume it has already been saved
      // It is wrapped in a promise to ensure the email is sent before redirecting

      var emailPromise = new Promise(function (resolve, reject) {
        previousUserResponse.then(function () {
          var emailResourceTypes = ['Email', 'EmailOutcome'];
          var modelName = emailResourceTypes.indexOf(resourceType) > -1 ? 'email-event' : 'trigger-text-event';

          var eventModel = _this3.get('store').createRecord(modelName, {
            node: node,
            session: session
          });

          if (resourceType === 'EmailOutcome' || resourceType === 'TriggerTextOutcome') {
            var outcome = _this3.get('outcomeHandler').calculateOutcome(session);

            eventModel.set('outcome', outcome);
          }

          eventModel.save().then(resolve, reject);
        });
      });
      this.pushPromise(emailPromise);
      this.createResponse(node);
      this.saveResponse(node, edge);
      return edge;
    },
    handleDataBranch: function handleDataBranch(node) {
      var handler = this.get('dataBranchHandler');
      var dataBranch = this.lookupRecord('data-branch', node.get('id'));
      var edge = handler.handle(dataBranch, this.get('session'));
      this.createResponse(node);
      this.saveResponse(node, edge);
      return edge;
    },
    handleOutcome: function handleOutcome(node) {
      if (!node) {
        return;
      }

      var resourceType = node.get('resourceType');
      var id = node.get('id');
      var shouldCalculateOutcome = false;
      var resource;

      if (resourceType) {
        if (resourceType === 'Redirect') {
          resource = this.lookupRecord('redirect', id);
          shouldCalculateOutcome = resource.get('url') === '{ outcome url }';
        } else if (resourceType === 'Outcome') {
          resource = this.lookupRecord('content-collection', id);
          shouldCalculateOutcome = true;
        } else if (resourceType.indexOf('Outcome') > -1) {
          shouldCalculateOutcome = true;
        }
      }

      if (shouldCalculateOutcome) {
        var session = this.get('session');
        var outcome = this.get('outcomeHandler').calculateOutcome(session);

        if (resource) {
          resource.set('outcome', outcome);
        }
      }
    },
    handlePopoutClick: function handlePopoutClick(edge) {
      var session = this.get('session');
      var redirect = this.lookupRecord('redirect', edge.get('childNode.id'));

      if (redirect && redirect.get('openInNewTab')) {
        var popoutPromise = this.get('store').createRecord('popout-click', {
          session: session,
          edge: edge
        }).save();
        this.pushPromise(popoutPromise);
        return popoutPromise;
      }
    },
    createResponse: function createResponse(node) {
      var session = this.get('session');
      var authToken = session.get('authToken');
      var response = this.get('store').createRecord('response', {
        authToken: authToken,
        session: session,
        node: node,
        startedAt: new _moment.default()
      });
      node.set('response', response);
    },
    saveResponse: function saveResponse(node, edge, formFields) {
      if (edge.get('childNode.resourceType') === 'Redirect') {
        var popoutClick = this.handlePopoutClick(edge);

        if (popoutClick) {
          return;
        }
      }

      var formFieldSubmissions = this.createFormFieldSubmissions(formFields);
      var response = node.get('response.content');
      this.incrementProperty('currentDepth');
      response.setProperties({
        edge: edge,
        formFieldSubmissions: formFieldSubmissions,
        endedAt: (0, _moment.default)()
      });
      var responsePromise = response.save();
      this.pushPromise(responsePromise);
      return responsePromise;
    },
    sendLoginRequest: function sendLoginRequest(provider, auth, formField) {
      var authorizationCode = decodeURIComponent(auth.authorizationCode);
      var redirectUri = decodeURIComponent(auth.redirectUri);
      this.get('ajax').request('POST', 'oauth_login', {
        oauth_login: {
          authorizationCode: authorizationCode,
          formFieldId: formField.get('id'),
          provider: provider,
          redirectUri: redirectUri,
          userSessionId: this.get('session.id')
        }
      });
    },
    createFormFieldSubmissions: function createFormFieldSubmissions(formFields) {
      var store = this.get('store');
      var session = this.get('session');

      if (!formFields) {
        return [];
      }

      return formFields.map(function (formField) {
        return store.createRecord('form-field-submission', {
          formField: formField,
          session: session,
          value: formField.get('value')
        });
      });
    },
    lookupRecord: function lookupRecord(model, id) {
      return this.get('store').peekRecord(model, id);
    },

    /* Google Analytics + Tag Manager */
    initializeThirdPartyAnalytics: function initializeThirdPartyAnalytics() {
      var ga = this.get('googleAnalytics');
      var sessionId = this.get('session.id');
      ga.setUserId(sessionId);
    },
    sendThirdPartyView: function sendThirdPartyView(node) {
      var ga = this.get('googleAnalytics');
      var gtm = this.get('googleTagManager');
      var contentCollection = this.lookupRecord('content-collection', node.get('id'));

      if (contentCollection) {
        ga.sendPageView(contentCollection);
        gtm.sendPageView(contentCollection);
      }
    },
    sendThirdPartyResponse: function sendThirdPartyResponse(contentPiece, formFields) {
      var gtm = this.get('googleTagManager');
      var contentCollection = contentPiece.get('contentCollection');

      if (Ember.isEmpty(formFields)) {
        gtm.sendResponse(contentCollection, contentPiece);
      } else {
        gtm.sendFormSubmission(contentCollection, formFields);
      }
    },
    pushPromise: function pushPromise(promise) {
      this.get('savePromises').pushObject(promise);
    }
  });

  _exports.default = _default;
});