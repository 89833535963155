define("moments/campaign/index/controller", ["exports", "moments/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: Ember.inject.service(),
    edgeMessageHandler: Ember.inject.service(),
    stateManager: Ember.inject.service(),
    currentContentCollection: null,
    displayedContentCollections: [],
    firedEventHooks: Ember.Object.create(),
    inReviewMode: false,
    numComments: null,
    showComments: false,
    currentProgressDepth: Ember.computed.readOnly('stateManager.currentDepth'),
    outcomes: Ember.computed.readOnly('model.outcomeGroup.outcomes'),
    session: Ember.computed.readOnly('stateManager.session'),
    showPreviewOnlyComponents: Ember.computed.readOnly('session.isPreviewing'),
    commentsUrl: Ember.computed('currentContentCollection', function () {
      var host = _environment.default.APP.selfServeHost;

      if (_environment.default.environment === 'canary') {
        host = "https://".concat(window.location.host.replace('mo', 'ss'));
      }

      return "".concat(host, "/node-comments/").concat(this.get('currentContentCollection.id'));
    }),
    actions: {
      toggleComments: function toggleComments() {
        this.toggleProperty('showComments');
        this.postShowCommentsMessage(this.get('showComments'));
      },
      respond: function respond(contentPiece) {
        var formFields = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
        var stateManager = this.get('stateManager');
        var formSubmissionIsInvalid = formFields.isAny('validations.isValid', false);
        var hasResponded = stateManager.hasRespondedTo(contentPiece);

        if (hasResponded || formSubmissionIsInvalid) {
          return;
        }

        var nextState = stateManager.getNextState(contentPiece, formFields);
        this.firePostResponseHooks(contentPiece, formFields, nextState);

        if (!nextState) {
          return;
        }

        this.prepareAndTransitionToNextScreen(nextState, contentPiece);
      },
      changeScreen: function changeScreen(contentCollection) {
        this.resetCampaign(contentCollection);
      },
      startOver: function startOver() {
        var firstState = this.get('stateManager').getInitialState();
        this.resetCampaign(firstState);
      },
      saveSocialShare: function saveSocialShare(socialMediaType) {
        this.get('stateManager').saveSocialShare(socialMediaType, this.get('currentContentCollection'));
      },
      socialLogin: function socialLogin(formField) {
        var _this = this;

        var contentPiece = formField.get('contentPiece');
        var stateManager = this.get('stateManager');
        var hasResponded = stateManager.hasRespondedTo(contentPiece);

        if (hasResponded) {
          return;
        }

        stateManager.triggerSocialLogin(formField).then(function (nextState) {
          _this.firePostResponseHooks(contentPiece, [formField], nextState);

          if (!nextState) {
            return;
          }

          _this.prepareAndTransitionToNextScreen(nextState, contentPiece);
        });
      },
      runRenderActions: function runRenderActions() {
        this.fireEventHooks('show');
      },
      updateOutcome: function updateOutcome(outcome) {
        this.get('stateManager').setPreviewOutcome(this.get('currentContentCollection'), outcome.get('id'));
        this.get('queryParams').updateParam('outcome', outcome.get('id'));
      }
    },
    resetCampaign: function resetCampaign(contentCollection) {
      if (this.get('session.isPreviewing')) {
        this.set('displayedContentCollections', []);
        this.set('firedEventHooks', Ember.Object.create());
        this.get('stateManager').handlePreviewScreen(contentCollection);
        this.prepareAndTransitionToNextScreen(contentCollection);
        this.store.peekAll('form-field').forEach(function (formField) {
          formField.set('value', null);
        });
      }
    },
    fireEventHooks: function fireEventHooks(event) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var contentCollection = this.get('currentContentCollection');
      var id = contentCollection.get('id');
      var firedEventHooks = this.get('firedEventHooks');
      var eventHookKey = "".concat(event, ":").concat(id);

      if (firedEventHooks.get(eventHookKey)) {
        return;
      }

      firedEventHooks.set(eventHookKey, true);
      options.contentCollection = contentCollection;
      options.type = event;
      this.fireJsHook(options);
      this.fireTrackingPixels(contentCollection, event);
    },
    fireJsHook: function fireJsHook(options) {
      var _window = window,
          jebbitJSHooks = _window.jebbitJSHooks;
      var type = options.type,
          contentCollection = options.contentCollection,
          campaign = options.campaign;
      var resource = contentCollection || campaign;
      if (!resource) return;
      var resourceId = resource.get('id');
      var functionName = "on_".concat(type, "_").concat(resourceId);
      Object.assign(options, {
        ENV: _environment.default,
        context: this,
        session: this.get('session'),
        queryParams: this.get('queryParams.params')
      });
      jebbitJSHooks.run(functionName, options);
    },
    firePostResponseHooks: function firePostResponseHooks(contentPiece, formFields, nextState) {
      contentPiece.set('contentCollection.userHasResponded', true);
      this.showEdgeMessage(contentPiece);
      this.fireEventHooks('respond', {
        contentPiece: contentPiece,
        formFields: formFields,
        nextState: nextState
      });
    },
    fireTrackingPixels: function fireTrackingPixels(contentCollection, event) {
      var trackingPixels = contentCollection.get('nodeTrackingPixels');

      if (trackingPixels) {
        trackingPixels.toArray().forEach(function (trackingPixel) {
          if (trackingPixel.get('event') === event) {
            var image = document.createElement('img');
            image.className = "tracking-pixel";
            image.src = trackingPixel.get('url');
            image.style.cssText = 'width: 1px; height: 1px; position: fixed; left: -1000px; top: -1000px; visibility: hidden';
            document.body.appendChild(image);
          }
        });
      }
    },
    redirectUser: function redirectUser(redirect) {
      var delay = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var queryParams = this.get('queryParams');

      var _redirect$getProperti = redirect.getProperties(['openInNewTab', 'url']),
          openInNewTab = _redirect$getProperti.openInNewTab,
          url = _redirect$getProperti.url;

      if (openInNewTab) {
        return window.open(url, '_blank');
      }

      this.get('stateManager').waitForSaves().finally(function () {
        Ember.run.later(function () {
          if (window.parent === window || queryParams.findParam('embed')) {
            window.location.href = url;
          } else {
            window.parent.postMessage({
              action: 'redirect',
              target: 'jebbit',
              options: {
                link: url
              }
            }, '*');
          }
        }, delay);
      });
    },
    showEdgeMessage: function showEdgeMessage(contentPiece) {
      var responseHandler = this.get('edgeMessageHandler');
      var edgeMessage = responseHandler.getEdgeMessage(contentPiece);

      if (edgeMessage) {
        responseHandler.showEdgeMessage(edgeMessage);
      }
    },
    getNextStateDelay: function getNextStateDelay(modelName, contentPiece) {
      var edgeMessage = this.get('edgeMessageHandler').getEdgeMessage(contentPiece);

      if (!edgeMessage) {
        return 0;
      } else if (modelName === 'redirect' || edgeMessage.get('showLivePolling')) {
        return edgeMessage.get('durationSeconds');
      }
    },
    preloadImages: function preloadImages(childImages) {
      if (!childImages) {
        return;
      }

      Ember.run.later(function () {
        childImages.forEach(function (src) {
          var image = new Image();
          image.src = src;
        });
      }, 500);
    },
    prepareAndTransitionToNextScreen: function prepareAndTransitionToNextScreen(nextState, contentPiece) {
      var modelName = nextState.get('constructor.modelName');
      var delay = this.getNextStateDelay(modelName, contentPiece);

      if (modelName === 'redirect') {
        return this.redirectUser(nextState, delay);
      }

      this.preloadImages(nextState.get('childImages'));
      this.transitionToNextScreen(nextState, delay);
    },
    transitionToNextScreen: function transitionToNextScreen(nextState, delay) {
      var _this2 = this;

      this.addTransitionClass();
      Ember.run.later(function () {
        _this2.pauseVideos();

        _this2.get('displayedContentCollections').pushObject(nextState);

        nextState.set('isNext', true);
        Ember.run.later(function () {
          nextState.set('isNext', false);

          _this2.set('currentContentCollection', nextState);
        });
      }, delay);
    },
    addTransitionClass: function addTransitionClass() {
      var transition = this.get('model.transition');
      var $appRoot = Ember.$('body > .ember-view');
      var transitionClasses = {
        horizontal: ['transition', 'horizontal'],
        vertical: ['transition', 'vertical'],
        fade: ['transition', 'fade']
      }[transition];

      if (transitionClasses) {
        transitionClasses.forEach(function (className) {
          $appRoot.addClass(className);
        });
      }
    },
    pauseVideos: function pauseVideos() {
      Ember.$('video').trigger('pause');
    },
    setSessionIsViewed: function setSessionIsViewed() {
      this.get('stateManager').setSessionIsViewed();
      this.postMessageToParent('jebbitSessionCreated');
    },
    postMessageToParent: function postMessageToParent(message) {
      window.parent.postMessage(message, '*');
    },
    postShowCommentsMessage: function postShowCommentsMessage(showComments) {
      var iframe = Ember.$('.comments-container iframe')[0];

      if (showComments && iframe) {
        iframe.contentWindow.postMessage({
          action: 'openedComments',
          campaignId: this.get('model.id')
        }, '*');
      }
    }
  });

  _exports.default = _default;
});