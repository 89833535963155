define("moments/components/comment-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o7gzNnSF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"messages \",[27,\"if\",[[23,[\"showComments\"]],\"active\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"numComments\"]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"num-comments\"],[9],[1,[21,\"numComments\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"button\"],[11,\"class\",\"basic-button\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"material-icons messages-icon\"],[9],[1,[21,\"icon\"],false],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"clickToggle\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/comment-icon/template.hbs"
    }
  });

  _exports.default = _default;
});