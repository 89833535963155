define("moments/components/progress-bar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GOuHNqcm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"progress-bar\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"partial-progress\"],[12,\"style\",[28,[\"width: \",[21,\"widthPercentage\"],\"%;\"]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/progress-bar/template.hbs"
    }
  });

  _exports.default = _default;
});