define("moments/campaign/index/route", ["exports", "moments/mixins/add-assets-to-dom"], function (_exports, _addAssetsToDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      inject = _Ember.inject;

  var _default = Ember.Route.extend(_addAssetsToDom.default, {
    stateManager: inject.service(),
    metadata: inject.service(),
    queryParams: inject.service(),
    afterModel: function afterModel(model) {
      this.addAssetsToDom(model);
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var stateManager = this.get('stateManager');
      var queryParams = this.get('queryParams');
      var outcomeId = queryParams.findParam('outcome');

      this._super.apply(this, arguments);

      this.get('metadata').setMetadata(model.get('campaignMetadata'));

      if (this.checkForReviewMode()) {
        return Ember.RSVP.resolve();
      }

      var createSession = stateManager.createSession(model);
      createSession.then(function () {
        var firstState = stateManager.getInitialState();

        if (firstState.get('constructor.modelName') === 'redirect') {
          return controller.redirectUser(firstState);
        }

        controller.set('displayedContentCollections', [firstState]);
        controller.set('currentContentCollection', firstState);
        controller.fireJsHook({
          type: 'show',
          campaign: model
        });
        controller.postMessageToParent('jebbitCampaignLoaded');

        if (stateManager.get('session.isPreviewing')) {
          stateManager.handlePreviewScreen(firstState, outcomeId);

          _this.setUpMessageCountListener(controller);
        }

        _this.setUpDeferredListener(controller);
      });
      controller.set('firedEventHooks', Ember.Object.create());
      this.fetchLivePollingData(model);
      return createSession;
    },
    setUpMessageCountListener: function setUpMessageCountListener(controller) {
      window.addEventListener('message', function (event) {
        var numComments = Ember.get(event, 'data.numComments');
        controller.set('numComments', numComments);
      }, false);
    },
    setUpDeferredListener: function setUpDeferredListener(controller) {
      window.addEventListener('message', function (event) {
        if (event && event.data == 'startJebbitCampaign') {
          controller.setSessionIsViewed();
        }
      });
    },
    fetchLivePollingData: function fetchLivePollingData() {
      var edgeMessages = this.store.peekAll('edge-message').filterBy('showLivePolling', true);
      var nodeIds = edgeMessages.map(function (edgeMessage) {
        return edgeMessage.get('contentPiece.contentCollection.id');
      });

      if (nodeIds.length > 0) {
        this.store.query('live-polling-edge-stat', {
          node_ids: nodeIds
        });
      }
    },
    // If we are in review mode, remove all actions from the controller and show the full experience
    checkForReviewMode: function checkForReviewMode() {
      var controller = this.controller;
      var queryParams = this.get('queryParams');

      if (queryParams.findParam('review_mode')) {
        var noop = function noop() {};

        Object.keys(controller.actions).forEach(function (fnName) {
          controller.set("actions.".concat(fnName), noop);
        });
        controller.setProperties({
          fireEventHooks: noop,
          inReviewMode: true,
          displayedContentCollections: this.store.peekAll('content-collection').toArray()
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});