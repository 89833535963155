define("moments/utils/create-script-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createScriptTag;

  function createScriptTag(src) {
    var scriptTag = document.createElement('script');
    scriptTag.src = src;
    Ember.$('body').prepend(scriptTag);
  }
});