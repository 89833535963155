define("moments/models/response", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    authToken: attr('string'),
    edge: belongsTo('edge'),
    endedAt: attr('string'),
    formFieldSubmissions: hasMany('form-field-submission'),
    session: belongsTo('session'),
    startedAt: attr('string')
  });

  _exports.default = _default;
});