define("moments/services/edge-message-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      inject = _Ember.inject;

  var _default = Ember.Service.extend({
    notifications: inject.service('notification-messages-service'),
    overlayScreenManager: inject.service(),
    getEdgeMessage: function getEdgeMessage(contentPiece) {
      if (!contentPiece) return;
      return contentPiece.get('edgeMessage.content');
    },
    showEdgeMessage: function showEdgeMessage(edgeMessage) {
      if (edgeMessage.get('text')) {
        var layout = edgeMessage.get('layout');

        if (layout === 'Flash Message') {
          this.handleFlashMessage(edgeMessage);
        } else if (layout === 'Overlay') {
          this.handleOverlay(edgeMessage);
        }
      }
    },
    handleFlashMessage: function handleFlashMessage(edgeMessage) {
      if (!edgeMessage.get('text')) {
        return;
      }

      this.get('notifications').addNotification({
        autoClear: true,
        clearDuration: edgeMessage.get('durationSeconds'),
        message: edgeMessage.get('text'),
        type: edgeMessage.get('icon') || 'none'
      });
    },
    handleOverlay: function handleOverlay(edgeMessage) {
      var options = {
        duration: edgeMessage.get('duration'),
        color: edgeMessage.get('color'),
        icon: edgeMessage.get('icon'),
        text: edgeMessage.get('text')
      };
      this.get('overlayScreenManager').show(options);
    }
  });

  _exports.default = _default;
});