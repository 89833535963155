define("moments/models/content-piece", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    childContentPieces: hasMany('content-piece', {
      inverse: 'parentContentPiece'
    }),
    livePollingEdgeStat: belongsTo('live-polling-edge-stat'),
    delayAppearance: attr('number'),
    displayOrder: attr('number'),
    contentCollection: belongsTo('content-collection'),
    edgeMessage: belongsTo('edge-message'),
    edge: belongsTo('edge'),
    formFields: hasMany('form-field'),
    layoutPartName: attr('string'),
    newTabRedirect: attr('object'),
    parentContentPiece: belongsTo('content-piece', {
      inverse: 'childContentPieces'
    }),
    type: attr('string'),
    style: attr('string'),
    originalValue: attr('string'),
    jsonValue: attr('object'),
    contentMetadata: Ember.computed.alias('contentPieceSupplement.metadata'),
    percentage: Ember.computed.alias('livePollingEdgeStat.percentage'),
    outcome: Ember.computed.readOnly('contentCollection.outcome'),
    isForm: Ember.computed('type', function () {
      var type = this.get('type');
      var formTypes = ['form', 'facebook-login', 'google-login'];
      return formTypes.indexOf(type) >= 0;
    }),
    value: Ember.computed('originalValue', 'outcome', 'jsonValue', function () {
      var regex = /^{ outcome (heading|image|description) }$/;

      var _this$getProperties = this.getProperties(['outcome', 'originalValue']),
          outcome = _this$getProperties.outcome,
          originalValue = _this$getProperties.originalValue;

      var value;

      if (outcome && regex.test(originalValue)) {
        var property = originalValue.replace('{ outcome ', '').replace(' }', '');
        value = outcome.get(property);
      }

      if (!value) {
        value = originalValue;
      }

      var jsonValue = this.get('jsonValue');

      if (outcome && outcome.get('imageUrl') && this.get('type') === 'image') {
        return outcome.get('imageUrl');
      }

      if (jsonValue) {
        return jsonValue;
      }

      return value;
    })
  });

  _exports.default = _default;
});