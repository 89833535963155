define("moments/components/paper-input/component", ["exports", "moments/components/base-focusable/component", "mailcheck"], function (_exports, _component, _mailcheck) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      Logger = _Ember.Logger;

  var _default = _component.default.extend({
    attributeBindings: ['flex', 'flex-layout:layout'],
    classNames: ['input-container'],
    classNameBindings: ['hasValue:input-has-value', 'isInvalidAndTouched:md-input-invalid', 'eitherIcon:md-has-icon', 'iconRight:md-icon-right', 'focused:md-input-focused', 'block:md-block'],
    autofocus: false,
    hideAllMessages: false,
    inputStyle: null,
    isTouched: false,
    lastIsInvalid: undefined,
    tabindex: null,
    onBlur: function onBlur() {},
    onChange: function onChange() {},
    onValidityChange: function onValidityChange() {},
    hasValue: Ember.computed('value', 'isNativeInvalid', function () {
      var value = this.get('value');
      var isNativeInvalid = this.get('isNativeInvalid');
      return !Ember.isEmpty(value) || isNativeInvalid;
    }),
    inputElementId: Ember.computed('elementId', function () {
      return "input-".concat(this.get('elementId'));
    }),
    label: Ember.computed('text', 'inputStyle', function () {
      return this.get('inputStyle') === 'Material' ? this.get('text') : null;
    }),
    placeholder: Ember.computed('text', 'inputStyle', function () {
      return this.get('inputStyle') === 'Plain' ? this.get('text') : null;
    }),
    type: Ember.computed('text', function () {
      var text = this.get('text') || '';

      if (text.match(/email/gi)) {
        return 'email';
      } else if (text.match(/phone number/gi) || text.match(/phone #/gi) || text.toLowerCase() === 'phone' || text.toLowerCase() === 'phone*') {
        return 'tel';
      } else {
        return 'text';
      }
    }),

    /**
     * The result of isInvalid is appropriate for controlling the display of
     * validation error messages. It also may be used to distinguish whether
     * the input would be considered valid after it is touched.
     *
     * @public
     *
     * @return {boolean} Whether the input is or would be invalid.
     *    false: input is valid (touched or not), or is no longer rendered
     *    true: input has been touched and is invalid.
     */
    isInvalid: Ember.computed.or('validationErrorMessages.length', 'isNativeInvalid'),
    isValid: Ember.computed.not('isInvalid'),
    isInvalidAndTouched: Ember.computed.and('isInvalid', 'isTouched'),
    renderCharCount: Ember.computed('value', function () {
      var currentLength = this.get('value') ? this.get('value').length : 0;
      return "".concat(currentLength, "/").concat(this.get('maxlength'));
    }),
    eitherIcon: Ember.computed.or('icon', 'iconRight'),
    customValidations: [],
    errors: [],

    /**
     * Computed property that validate the input and return an array of error
     * objects, each with an ng-message code and an error message.
     *
     * @public
     */
    validationErrorMessages: Ember.computed('value', 'errors.[]', 'customValidations.[]', function () {
      var _this2 = this;

      var validations = Ember.A();
      var messages = Ember.A(); // custom validations

      var customValidations = this.get('customValidations');
      (false && !(Ember.isArray(customValidations)) && Ember.assert('`customValidations` must be an array', Ember.isArray(customValidations)));
      validations.pushObjects(customValidations); // execute validations

      var currentValue = this.get('value');
      validations.forEach(function (validation) {
        (false && !(validation && validation.validate && typeof validation.validate === 'function') && Ember.assert('validation must include an `validate(value)` function', validation && validation.validate && typeof validation.validate === 'function'));

        try {
          var valParam = Ember.get(validation, 'param');
          var paramValue = valParam ? _this2.get(valParam) : undefined;

          if (!validation.validate(currentValue, paramValue)) {
            var message = _this2.get("errorMessages.".concat(valParam)) || Ember.get(validation, 'message');
            messages.pushObject({
              message: Ember.String.loc(message.string || message, paramValue, currentValue)
            });
          }
        } catch (error) {
          Logger.error('Exception with validation: ', validation, error);
        }
      }); // error messages array

      var errors = this.get('errors') || [];
      (false && !(Ember.isArray(errors)) && Ember.assert('`errors` must be an array', Ember.isArray(errors)));
      messages.pushObjects(errors.map(function (e) {
        return Ember.get(e, 'message') ? e : {
          message: e
        };
      }));
      return messages;
    }),
    // Lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      (false && !(this.get('onChange') !== undefined) && Ember.assert('{{paper-input}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined));
      this.notifyValidityChange();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('textarea')) {
        Ember.$(window).on("resize.".concat(this.elementId), Ember.run.bind(this, this.growTextarea));
      }
    },
    didRender: function didRender() {
      this.growTextarea(); // setValue below ensures that the input value is the same as this.value

      this.setValue(this.get('value'));
    },
    willClearRender: function willClearRender() {
      this.get('onValidityChange')(false);
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('textarea')) {
        Ember.$(window).off("resize.".concat(this.elementId));
      }
    },
    growTextarea: function growTextarea() {
      if (this.get('textarea')) {
        var inputElement = this.$('input, textarea');
        inputElement.addClass('md-no-flex').attr('rows', 1);
        var minRows = this.get('passThru.rows');

        if (minRows) {
          if (!this.lineHeight) {
            inputElement.get(0).style.minHeight = 0;
            this.lineHeight = inputElement.get(0).clientHeight;
            inputElement.get(0).style.minHeight = null;
          }

          var newRows = Math.round(Math.round(this.getHeight(inputElement) / this.lineHeight));
          var rowsToSet = Math.min(newRows, minRows);
          inputElement.css('height', "".concat(this.lineHeight * rowsToSet, "px")).attr('rows', rowsToSet).toggleClass('md-textarea-scrollable', newRows >= minRows);
        } else {
          inputElement.css('height', 'auto');
          inputElement.get(0).scrollTop = 0;
          var height = this.getHeight(inputElement);

          if (height) {
            inputElement.css('height', "".concat(height, "px"));
          }
        }

        inputElement.removeClass('md-no-flex');
      }
    },
    getHeight: function getHeight(inputElement) {
      var _inputElement$get = inputElement.get(0),
          offsetHeight = _inputElement$get.offsetHeight;

      var line = inputElement.get(0).scrollHeight - offsetHeight;
      return offsetHeight + (line > 0 ? line : 0);
    },
    notifyValidityChange: function notifyValidityChange() {
      var isValid = this.get('isValid');
      var lastIsValid = this.get('lastIsValid');

      if (lastIsValid !== isValid) {
        this.get('onValidityChange')(isValid);
        this.set('lastIsValid', isValid);
      }
    },
    setValue: function setValue(value) {
      if (this.$('input, textarea').val() !== value) {
        this.$('input, textarea').val(value);
      }
    },
    suggestEmail: function suggestEmail() {
      var _this = this;

      _mailcheck.default.run({
        email: _this.get('value'),
        domains: ['msn.com', 'comcast.net', 'earthlink.net', 'qq.com', 'sky.com', 'icloud.com', 'mac.com', 'googlemail.com', 'att.net', 'cox.net', 'gmail.com', 'ymail.com', 'aim.com', 'verizon.net', 'google.com', 'sbcglobal.net', 'aol.com', 'me.com', 'charter.net'],
        secondLevelDomains: ["yahoo", "hotmail", "mail", "live", "outlook", 'gmail'],
        suggested: function suggested(suggestion) {
          // Only show suggestion if it contains a domain and TLD
          if (suggestion.domain.match(/\.\w/)) {
            _this.set('suggestion', suggestion);
          } else {
            _this.set('suggestion', null);
          }
        },
        empty: function empty() {
          _this.set('suggestion', null);
        }
      });
    },
    actions: {
      handleInput: function handleInput(e) {
        var _this3 = this;

        this.get('onChange')(e.target.value); // setValue below ensures that the input value is the same as this.value

        Ember.run.next(function () {
          _this3.setValue(_this3.get('value'));

          if (_this3.get('type') === 'email') {
            _this3.suggestEmail();
          }
        });
        this.growTextarea();
        var inputElement = this.$('input').get(0);
        this.set('isNativeInvalid', inputElement && inputElement.validity && inputElement.validity.badInput);
        this.notifyValidityChange();
      },
      handleBlur: function handleBlur(e) {
        this.get('onBlur')(e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      },
      takeSuggestion: function takeSuggestion() {
        var suggestedEmail = this.get('suggestion.full');
        this.get('onChange')(suggestedEmail);
        this.setValue(suggestedEmail);
        this.set('suggestion', null);
      }
    }
  });

  _exports.default = _default;
});