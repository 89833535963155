define("moments/services/cookie-handler", ["exports", "moments/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      inject = _Ember.inject;

  var _default = Ember.Service.extend({
    cookies: inject.service(),
    cookieDomain: Ember.computed(function () {
      if (_environment.default.APP.inDevelopment) {
        return 'localhost';
      }

      var domain = window.location.hostname;
      var withoutSubdomain = Ember.A(domain.split('.')).removeAt(0).join('.');
      return ".".concat(withoutSubdomain);
    }),
    cookieOptions: Ember.computed('cookieDomain', function () {
      var options = {};
      options.domain = this.get('cookieDomain');
      options.path = '/';
      return options;
    }),
    clearCookie: function clearCookie(key) {
      // Copy options so they are not mutated by cookies.clear();
      var optionCopy = Object.assign({}, this.get('cookieOptions'));
      return this.get('cookies').clear(key, optionCopy);
    },
    readCookie: function readCookie(key) {
      return this.get('cookies').read(key);
    },
    writeCookie: function writeCookie(key, value) {
      var writeOptions = Object.assign({
        expires: 2
      }, this.get('cookieOptions'));
      this.get('cookies').write(key, value, writeOptions);
    }
  });

  _exports.default = _default;
});