define("moments/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j8RIxmVb",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"notification-container\"],false],[0,\"\\n\\n\"],[1,[27,\"overlay-screen\",null,[[\"options\",\"showScreen\"],[[23,[\"overlayScreenManager\",\"options\"]],[23,[\"showOverlayScreen\"]]]]],false],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/templates/application.hbs"
    }
  });

  _exports.default = _default;
});