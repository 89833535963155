define("moments/components/content-piece/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4N2w3zu4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"basic-button \",[21,\"buttonClass\"],\" \",[21,\"styleClass\"]]]],[12,\"style\",[21,\"paddingCSS\"]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"live-polling-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"widthOrHeightClass\"],\" animation\"]]],[12,\"style\",[21,\"livePollingStyle\"]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"poll-number\"],[9],[0,\"\\n        \"],[1,[21,\"percentage\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"content \",[21,\"contentClass\"]]]],[9],[0,\"\\n    \"],[1,[23,[\"contentPiece\",\"value\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"click\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/button/template.hbs"
    }
  });

  _exports.default = _default;
});