define("moments/components/content-piece/image/component", ["exports", "moments/components/content-piece/base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['image-wrapper'],
    didRender: function didRender() {
      var _this = this;

      Ember.run.later(function () {
        var image = _this.$('.image'); // TODO: Update the video campaign creator code to add content style
        // overrides of width 100% to all the images. Then, remove this code.


        if (image && image.width() === 0) {
          image.width('100%');
        }
      }, 200);
    },
    imageClass: Ember.computed('contentClass', function () {
      var contentClass = this.get('contentClass');
      return "image ".concat(contentClass);
    }),
    imageStyle: Ember.computed('contentPiece.{value,jsonValue.url}', function () {
      var url = this.get('contentPiece.jsonValue.url');

      if (url === '{ outcome image }') {
        url = this.get('contentPiece.value');
      }

      return "background-image: url(".concat(url, ");");
    })
  });

  _exports.default = _default;
});