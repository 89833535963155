define("moments/components/content-piece/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v5Svyl7J",
    "block": "{\"symbols\":[\"formField\"],\"statements\":[[7,\"form\"],[12,\"class\",[28,[\"content \",[21,\"contentPieceClass\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"sortedFormFields\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"content-piece/form-field\",null,[[\"formField\",\"onClick\",\"onSocialLoginClick\"],[[22,1,[]],[27,\"action\",[[22,0,[]],\"click\"],null],[27,\"action\",[[22,0,[]],\"socialLoginClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/form/template.hbs"
    }
  });

  _exports.default = _default;
});