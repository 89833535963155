define("moments/components/content-piece/button/component", ["exports", "moments/components/content-piece/base/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    onClick: function onClick() {},
    width: 0,
    height: 0,
    actions: {
      click: function click() {
        this.get('onClick')();
      }
    },
    color: Ember.computed.alias('edgeMessage.color'),
    edgeMessage: Ember.computed.alias('contentPiece.edgeMessage'),
    livePollingIsVisible: Ember.computed.and('showLivePolling', 'userHasResponded'),
    showLivePolling: Ember.computed.alias('edgeMessage.showLivePolling'),
    buttonClass: Ember.computed('contentPiece.id', function () {
      return "button-".concat(this.get('contentPiece.id'));
    }),
    livePollingStyle: Ember.computed('widthOrHeight', 'showLivePolling', 'userHasResponded', 'color', function () {
      var percentage = this.get('contentPiece.percentage');
      var cssRules = [];

      if (this.get('showLivePolling')) {
        if (this.get('userHasResponded')) {
          cssRules.push("".concat(this.get('widthOrHeight'), ": ").concat(percentage, "%"));
        } else {
          cssRules.push("".concat(this.get('widthOrHeight'), ": 0%"));
        }

        if (this.get('color')) {
          cssRules.push("background-color: ".concat(this.get('color')));
        }
      }

      return cssRules.join(';');
    }),
    widthOrHeightClass: Ember.computed('widthOrHeight', 'livePollingIsVisible', function () {
      if (this.get('livePollingIsVisible')) {
        return "poll-results-".concat(this.get('widthOrHeight'));
      } else {
        return '';
      }
    }),
    widthOrHeight: Ember.computed('width', 'height', function () {
      if (this.get('width') > this.get('height')) {
        return "width";
      }

      return "height";
    }),
    percentage: Ember.computed('livePollingIsVisible', function () {
      if (this.get('livePollingIsVisible')) {
        var percentage = this.get('contentPiece.percentage');
        return "".concat(parseInt(percentage), "%");
      }
    }),
    paddingCSS: Ember.computed('livePollingIsVisible', function () {
      if (this.get('livePollingIsVisible')) {
        return 'padding: 0;';
      }
    }),
    didInsertElement: function didInsertElement() {
      this.set('width', parseInt(this.$('button').css('width').slice(0, -2)));
      this.set('height', parseInt(this.$('button').css('height').slice(0, -2)));
    }
  });

  _exports.default = _default;
});