define("moments/components/content-piece/form-field/checkbox-array/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HHtCXqBK",
    "block": "{\"symbols\":[\"checkbox\",\"i\"],\"statements\":[[4,\"each\",[[23,[\"checkboxes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"layout-row flex checkbox-row\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"id\",\"type\",\"class\",\"checked\",\"click\"],[[22,1,[\"inputId\"]],\"checkbox\",\"form-field-checkbox\",[22,1,[\"isChecked\"]],[27,\"action\",[[22,0,[]],\"clickCheckbox\",[22,1,[]]],null]]]],false],[0,\"\\n\\n    \"],[7,\"label\"],[12,\"class\",[28,[\"form-field style-body \",[22,1,[\"class\"]]]]],[12,\"for\",[28,[[22,1,[\"inputId\"]]]]],[9],[0,\"\\n      \"],[1,[22,1,[\"text\"]],true],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"clickCheckbox\",[22,1,[]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"fieldErrors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[21,\"checkboxClass\"]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"paper-input-error\"],[9],[0,\"\\n      \"],[1,[21,\"fieldErrors\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/form-field/checkbox-array/template.hbs"
    }
  });

  _exports.default = _default;
});