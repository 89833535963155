define("moments/transforms/object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember$String = Ember.String,
      camelize = _Ember$String.camelize,
      underscore = _Ember$String.underscore;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(raw) {
      return this._transformObjectKeys(raw, camelize);
    },
    serialize: function serialize(raw) {
      return this._transformObjectKeys(raw, underscore);
    },
    _transformObjectKeys: function _transformObjectKeys(raw, stringTransform) {
      if (!Ember.$.isPlainObject(raw)) {
        return {};
      }

      return Object.keys(raw).reduce(function (object, key) {
        object[stringTransform(key)] = raw[key];
        return object;
      }, {});
    }
  });

  _exports.default = _default;
});