define("moments/services/metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    setMetadata: function setMetadata(metadata) {
      if (!metadata) {
        return;
      }

      var _metadata$getProperti = metadata.getProperties(['description', 'imageUrl', 'title']),
          description = _metadata$getProperti.description,
          imageUrl = _metadata$getProperti.imageUrl,
          title = _metadata$getProperti.title;

      var tagInfo = [{
        property: 'name',
        metaType: 'description',
        content: description
      }, {
        property: 'property',
        metaType: 'og:type',
        content: 'website'
      }, {
        property: 'property',
        metaType: 'og:title',
        content: title
      }, {
        property: 'property',
        metaType: 'og:description',
        content: description
      }, {
        property: 'property',
        metaType: 'og:image',
        content: imageUrl
      }, {
        property: 'name',
        metaType: 'twitter:card',
        content: 'summary'
      }, {
        property: 'name',
        metaType: 'twitter:title',
        content: title
      }, {
        property: 'name',
        metaType: 'twitter:description',
        content: description
      }, {
        property: 'name',
        metaType: 'twitter:image',
        content: imageUrl
      }];
      Ember.$('head').find('title').text(title);
      tagInfo.forEach(this._createMetaTag);
    },
    _createMetaTag: function _createMetaTag(info) {
      var metaType = info.metaType,
          content = info.content,
          property = info.property;
      var metaStr = "<meta ".concat(property, "=\"").concat(metaType, "\" content=\"").concat(content, "\" />");
      Ember.$('head').append(metaStr);
    }
  });

  _exports.default = _default;
});