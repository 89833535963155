define("moments/components/overlay-screen/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      inject = _Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['overlay-screen-container'],
    classNameBindings: ['showScreen:show'],
    attributeBindings: ['style'],
    overlayScreenManager: inject.service(),
    showScreen: Ember.computed.alias('overlayScreenManager.showScreen'),
    options: Ember.computed.alias('overlayScreenManager.options'),
    notificationIcon: Ember.computed('options.icon', function () {
      var icon = this.get('options.icon');
      var icons = {
        check: 'done',
        clear: 'clear',
        'thumb up': 'thumb_up',
        'thumb down': 'thumb_down'
      };
      return icons[icon];
    }),
    hideScreen: function hideScreen() {
      this.get('overlayScreenManager').hide();
    },
    // Use JQuery click listener instead of the click function for iOS compatibility
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$().click(function () {
        Ember.run(function () {
          return _this.hideScreen();
        });
      });
    }
  });

  _exports.default = _default;
});