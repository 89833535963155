define("moments/models/email-event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;

  var _default = _emberData.default.Model.extend({
    outcome: belongsTo('outcome'),
    node: belongsTo('node'),
    session: belongsTo('session')
  });

  _exports.default = _default;
});