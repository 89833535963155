define("moments/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var campaignId = Ember.$('meta[name="campaign-id"]').attr('content');

      if (campaignId) {
        this.transitionTo('campaign', campaignId);
      }
    }
  });

  _exports.default = _default;
});