define("moments/components/overlay-screen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2Vb67PJj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"overlay-background\"],[12,\"style\",[28,[\"background: \",[23,[\"options\",\"color\"]]]]],[9],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"overlay-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progress-bar-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"progress-bar\"],[12,\"style\",[28,[\"background: \",[23,[\"options\",\"color\"]]]]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"partial-progress\"],[12,\"style\",[28,[\"width: \",[23,[\"options\",\"width\"]],\"%; transition: width \",[23,[\"options\",\"duration\"]],\"s linear;\"]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"notificationIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"i\"],[12,\"class\",[28,[\"material-icons \",[21,\"notificationIcon\"]]]],[9],[1,[21,\"notificationIcon\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text style-body\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[1,[23,[\"options\",\"text\"]],true],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/overlay-screen/template.hbs"
    }
  });

  _exports.default = _default;
});