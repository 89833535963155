define("moments/campaign/content/route", ["exports", "moments/campaign/index/route", "moments/config/environment"], function (_exports, _route, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _Ember = Ember,
      inject = _Ember.inject;

  var _default = _route.default.extend({
    stateManager: inject.service(),
    queryParams: inject.service(),
    model: function model(params) {
      this.set('contentCollectionId', params.content_collection_id);
      return this.modelFor('campaign');
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      var createSession = this._super.apply(this, arguments);

      var contentCollection = model.get('contentCollections').findBy('id', this.get('contentCollectionId'));
      var outcomeId = this.get('queryParams').findParam('outcome');

      if (!contentCollection && !_environment.default.APP.inDevelopment) {
        this.transitionTo('not-found');
      }

      createSession.then(function () {
        var stateManager = _this.get('stateManager');

        controller.set('displayedContentCollections', [contentCollection]);
        controller.set('currentContentCollection', contentCollection);
        stateManager.handlePreviewScreen(contentCollection, outcomeId);
      });
    },
    renderTemplate: function renderTemplate(controller) {
      this.render('campaign/index', {
        controller: controller
      });
    }
  });

  _exports.default = _default;
});