define("moments/components/social-button/facebook/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z5wQ4i6C",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"class\",\"social-icon\"],[11,\"src\",\"/images/facebook-icon-white.png\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"text\"],[9],[1,[21,\"text\"],true],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/social-button/facebook/template.hbs"
    }
  });

  _exports.default = _default;
});