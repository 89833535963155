define("moments/components/content-piece/text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v+y0MWQO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"text \",[21,\"styleClass\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"content \",[21,\"contentClass\"]]]],[9],[0,\"\\n    \"],[1,[23,[\"contentPiece\",\"value\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/text/template.hbs"
    }
  });

  _exports.default = _default;
});